$(function () {
    const tiles = $('.oma-candidate-tile');
    if (!tiles.length) return;

    var modal;
    var closeButton;
    var previousButton;
    var nextButton;
    var previous;
    var next;
    var scrollY;
    const noOfTiles = tiles.length;
    let currentIndex;

    if (tiles.length > 0) {
        for (var i = 0; i < noOfTiles; i++) {
            $(tiles[i]).prop('data-tile-number', i);
        }
    }
      
    $(tiles).click(function () {
        if (modal) {
            modal = null;
        }
        modal = $(this)[0].nextElementSibling;

        openModal();
    });

    function openModal() {
        $(modal).addClass("active");
        disableScroll();
        closeButton = $(modal).find('.close-button');
        previousButton = $(modal).find('.modal-previous-button');
        nextButton = $(modal).find('.modal-next-button');
        $($(modal).find('.oma-candidate-block-modal')[0]).scrollTop(0);

        const tileId = $($(modal)[0].previousElementSibling).prop('data-tile-number');
        currentIndex = tileId;
        
        previous = (currentIndex - 1);
        next = (currentIndex + 1)

        if (previous < 0) {
            $(previousButton).css('visibility', 'hidden');
        }

        if (next >= noOfTiles) {
            $(nextButton).css('visibility', 'hidden');
        }

        $.each(previousButton, function (i, button) {
            $(button).click(() => {
                goToPreviousCandidate();
            });
        });

        $.each(nextButton, function (i, button) {
            $(button).click(() => {
                goToNextCandidate();
            });
        });

        /*
        if (!(event.target == modal || event.target == closeButton[0])) {
            body.addEventListener('scroll', disableScroll);
        }*/

        $(window).click(function (event) {
            if (!!modal && !!closeButton[0] && (event.target == modal || event.target == closeButton[0])) {
                closeModal();
            }
        });

        $(document).keyup(function (e) {
            if (!!modal && modal.classList.contains("active") && e.keyCode == 27) { // Esc
                closeModal();
            }
        });
    }

    function disableScroll() {
        scrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.top = `-${scrollY}px`;
    }
    
    function closeModal() {
        $(modal).removeClass("active");
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, scrollY);

        const iframe = $($('.candidate-video-iframe')[currentIndex])[0];

        if (iframe !== undefined) {
            var source = iframe.src;
            iframe.src = source;
        }

        modal = null;
        $(previousButton).off();
        $(nextButton).off();
        $(closeButton).off();
        previousButton = null;
        nextButton = null;
        closeButton = null;
        previous = null;
        next = null;
        //body.removeEventListener('scroll', disableScroll);
    }

    function goToPreviousCandidate() {
        if (modal === null) {
            return;
        }
        const previousModal = $(tiles[previous])[0].nextElementSibling;
        closeModal();
        modal = previousModal;
        openModal();
    }

    function goToNextCandidate() {
        if (modal === null) {
            return;
        }
        const nextModal = $(tiles[next])[0].nextElementSibling;
        closeModal();
        modal = nextModal;
        openModal();
    }

    window.addEventListener('scroll', () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
});