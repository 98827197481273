if ($("#pafForm").length > 0) {
    function timestamp() {
        var response = document.getElementById("g-recaptcha-response");
        if (response == null || response.value.trim() == "") {
            var elems = JSON.parse(
                document.getElementsByName("captcha_settings")[0].value
            );
            elems["ts"] = JSON.stringify(new Date().getTime());
            document.getElementsByName(
                "captcha_settings"
            )[0].value = JSON.stringify(elems);
        }
    }
    function captcha_callback(key) {
        if (key !== "") {
            $("#btnPafSubmit").removeAttr("disabled");
        }
    }
    function captcha_expired() {
        $("#btnPafSubmit").attr("disabled", "disabled");
    }

    function validCheck(item) {

        if (item.is(":invalid")) {

            formValid = false;

            item.parent().find("label").css('color', '#f30000');
            let error = item.parent().find(".error");

            if (error.text() != "") {
                error.show();
            } else {
                error.text("This field is required");
                error.show();
            }
        } else {
            item.parent().find("label").css('color', 'initial');
            item.parent().find(".error").hide();
        }
    }
    let capInt;
    capInt = setInterval(timestamp, 500);
    function updateDemo() {

        $(".pafdemo").empty();
        $(".pafdemo").append("<li class='pafbranches'>Target Branch Societies</li>");
        $(".pafbranches").append(function () {
            var branchHtml = "<ul>";
            if ($("#Target_Location__c").val().length > 0) {
                $("#Target_Location__c").val().forEach(element => {
                    if (element.length > 2) {
                        branchHtml += "<li>" + element + "</li>";
                    }
                });
            } else {
                branchHtml += "<li>None Selected</li>";
            }
            branchHtml += "</ul>";
            return branchHtml;
        });
        $(".pafdemo").append("<li class='pafaudiences'>Target Audiences</li>");
        $(".pafaudiences").append(function () {
            var audienceHtml = "<ul>";
            if ($("#Target_Audience__c").val().length > 0) {
                $("#Target_Audience__c").val().forEach(element => {
                    if (element.length > 2) {
                        audienceHtml += "<li>" + element + "</li>";
                    }
                });
            } else {
                audienceHtml += "<li>None Selected</li>";
            }
            audienceHtml += "</ul>";
            return audienceHtml;
        });

    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    formValid = false;
    $(document).ready(function () {
        $("#btnPafSubmit").attr("disabled", "disabled");

        $("p.error").insertAfter('ul.inputs>li>label,  ul.checkboxes>li>label');
        $("p.error").hide();
        $(".checkboxes.declaration p.error").remove();

        $("input").blur(function () {
            var item = $(this);

            validCheck(item);

        });
        $("ul.inputs textarea").on('blur', function () {
            var item = $(this);


            validCheck(item);

        });
        function checkSendDate(inputValue) {
            var now = new Date(),
                minDate = new Date().setDate(now.getDate() + 14)
            date = new Date(inputValue).getTime();
            return date >= minDate;
        }

        let minDate = new Date();
        let maxDate = new Date();
        minDate = new Date(minDate.setDate(new Date().getDate() + 14)); //2 weeks
        maxDate = new Date(maxDate.setDate(new Date().getDate() + 365)); // 1 year
        


        $('#Send_Date__c').prop("min", formatDate(minDate));
        $('#Send_Date__c').prop("max", formatDate(maxDate));
        
        $("#Target_Location__c").on("change", function () {
            $("option[data-isdistrict='true']:selected").each(function (i, elem) {
                if ($('*[data-district="' + elem.value + '"]:selected').length == 0) {
                    $('*[data-district="' + elem.value + '"]').each(function (i, e) {
                        $(this).prop("selected", "true");
                    })
                } else {
                    if ($('*[data-district="' + elem.value + '"]:selected').length < $('*[data-district="' + elem.value + '"]').length) {
                        $(elem).prop("selected", false);
                    }
                }
            })
            updateDemo();
        })
        $("#Target_Audience__c").on("change", updateDemo);

        $("#btnPafSubmit").on('click', function () {
            formValid = true;
            $('ul.inputs li input,ul.inputs textarea, ul.inputs select, ul.checkboxes input').each(function () {
                var item = $(this);
                validCheck(item);
            });

            if (formValid) {

                let dateValid = checkSendDate($("#Send_Date__c").val());
                formValid = dateValid;
            }
            //validCheck(item);

            if (formValid) {
                $("#btnPafSubmit").attr('disabled', 'disabled');
                if ($('.paf-submit-notification').length == 0) {
                    $("#btnPafSubmit").after("<p class='paf-submit-notification'>Please wait while we send the data to OMA.</p>")
                } else {
                    $('.paf-submit-notification').text('Please wait while we send the data to OMA.')
                }
                $("option[data-isdistrict='true']").hide();
                $("#First_Name__c, #Last_Name__c").hide();

                if (!$("#Primary_Contact__c").val()) {
                    $("#Primary_Contact__c").val($("#First_Name__c").val().trim() + " " + $("#Last_Name__c").val().trim());

                }

                var json = JSON.stringify($("#pafForm").serializeArray().reduce(function (json, { name, value }) {

                    if (json[name] != undefined) {
                        if (value.length > 2) {
                            json[name] += (";" + value);
                        }
                    } else {
                        if (value.length > 2) {
                            json[name] = value;
                        }
                    }
                    return json;
                }));

                //PCFA API call
                $.ajax({
                    url: "/sfApi/Salesforce/PostPAFCase/",
                    type: "POST",
                    processData: false,
                    headers: { "accept": "application/json;odata=verbose", "content-type": "application/json" },
                    data: json,

                    success: function (result) {
                        if (result.success) {
                            let heading = $(".oma-text-block-title")[0].nodeName;
                            heading = Number(heading.substr(1, 1));
                            heading++;
                            let newHead = "H" + heading.toString();

                            $("#pafForm").hide().after("<div class='success'><" + newHead + " class='title'>Your Practice Announcement Request form has been submitted. Check the email you have on file.</" + newHead + "><p>You should receive an email within 24 hours with confirmation that we have received the form. If you do not receive a confirmation, please email <a href='mailto:paf@oma.org'>paf@oma.org</a>.</div>");
                            clearInterval(capInt);
                        }
                    },
                    error: function (error) {
                        $("#btnPafSubmit").removeAttr("disabled");
                        $("#First_Name__c, #Last_Name__c").show();
                        $("option[data-isdistrict='true']").show();
                        $(".paf-submit-notification").text("There was an error when submitting your form. Please try again later or email paf@oma.org;");
                       

                    }
                });
            } else {
                if ($(".paf-submit-notification").length == 0) {
                    $("#btnPafSubmit").after("<p class='paf-submit-notification'>Unable to submit if the form is incomplete. Please review for error messages.</p>")
                } else {
                    $(".paf-submit-notification").text("Unable to submit if the form is incomplete. Please review for error messages.");
                }
            }
            return false;
        });
    });
}
