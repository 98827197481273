$(function () {

    let _hash = window.location.hash;

    if (_hash === "") {
        const queryParams = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        try {
            const documentIds = document.querySelectorAll('[id]');
            var idArray = Array.prototype.map.call(documentIds, function (element) {
                return element.id;
            });
            if (idArray.includes(queryParams.anchor)) {
                document.getElementById(queryParams.anchor).scrollIntoView();
                _hash = `#${queryParams.anchor}`;
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const accordions = $('.jsSearchableExpandingContent');
    if (!accordions.length) return;

    const CARD_HEADER = '.jsSearchableCardHeader',
        CARD_BODY = '.jsSearchableCardBody',
        COLLAPSE_BTN = '.jsSearchableCollapser',
        ACTIVE_CLASS = 'active',
        SEARCH_BAR = '.jsAccordianSearchBar',
        cardBodies = $(CARD_BODY, accordions);
    let totalOccurrences = 0;
    var searchText, currBlock, currBlockTitle, currBlockAccordianTitles, currBlockAccordianBodies, targetId;
    $(cardBodies).on('show.bs.collapse', function () {
        $(this).siblings(CARD_HEADER).addClass(ACTIVE_CLASS);
    });
    $(cardBodies).on('hide.bs.collapse', function () {
        $(this).siblings(CARD_HEADER).removeClass(ACTIVE_CLASS);
    });
    $(".oma-searchable-expanding-content__clear").on("click", function () {
        $('.oma-searchable-expanding-content__search').val("");
        resetSearch();
    });
    function setBlocksFromTarget(target) {
        targetId = target.id.replace(/\s/g, '');
        searchText = $(target).val();
        currBlock = $(target).parents().find('.jsSearchableExpandingContent')
        currBlockTitle = $(currBlock).find('.oma-searchable-expanding-content__title');
        currBlockAccordianTitles = $(currBlock).find('.oma-searchable-expanding-content__button');
        currBlockAccordianBodies = $(currBlock).find('.oma-searchable-expanding-content__inner-content');
    }
    function highlightSearchText() {
        currBlockTitle.each(function (index, title) {
            if (title.innerText.toLowerCase().includes(searchText.toLowerCase())) {
                totalOccurrences = (totalOccurrences + countOccurances(title.innerText.toLowerCase(), searchText.toLowerCase()));
                title.innerHTML = replaceMatch(title.innerText, searchText);
            }
        });
        currBlockAccordianTitles.each(function (index, accordianTitle) {
            var accordian = $(accordianTitle).children('.accordionTitle')[0];
            accordianTitle = $(accordian).children('.accordionTitleText')[0];
            if (accordianTitle.innerText.toLowerCase().includes(searchText.toLowerCase())) {
                const accordion = $(accordianTitle).closest('.jsSearchableExpandingContent');
                var accordianId = "#" + accordianTitle.innerText.replace(/\s/g, '').replace(/[^a-zA-Z0-9 ]/g, '');
                $(accordion.find(accordianId + CARD_BODY)).collapse('show');
            }

            $(accordianTitle).html(regReplace($(accordianTitle).html(), searchText));
        });
        currBlockAccordianBodies.each(function (index, accordianBody) {


            $(accordianBody).html(regReplace($(accordianBody).html(), searchText));
            if (accordianBody.innerText.toLowerCase().includes(searchText.toLowerCase())) {
                const accordion = $(accordianBody).closest('.jsSearchableExpandingContent');
                var accordianId = "#" + accordianBody.id.replace(/\s/g, '').replace(/[^a-zA-Z0-9 ]/g, '');
                $(accordion.find(accordianId + CARD_BODY)).collapse('show');
            }
        });
        $(COLLAPSE_BTN, accordions).click(function () {
            const accordion = $(this).closest('.jsSearchableExpandingContent');
            const isCollapseOthers = accordion.data('collapse-others') === 'True';
            if (isCollapseOthers) {
                accordion.find(CARD_BODY).collapse('hide');
            }
            $(this).closest(CARD_HEADER).next(CARD_BODY).collapse('toggle');
        });
        totalOccurrences = $('mark').length;

    };
    $("")
    function collapseAll() {
        $('#' + targetId + '.lblCount').css('display', 'none');
        currBlockAccordianBodies.each(function (index, accordianBody) {
            const accordion = $(accordianBody).closest('.jsSearchableExpandingContent');
            accordion.find(CARD_BODY).collapse('hide');
        });
    }
    function postSearch() {
        $('#' + targetId + '.lblCount').text("Search Count: " + totalOccurrences);
        $('#' + targetId + '.lblCount').css('display', 'block');
        var firstSearchRes = $(currBlock).find('mark')[0];
        $('.jsSearchableExpandingContent').each(function (index, element) {
            var searchcount = $(element).find('mark');
            $(searchcount).each(function (index, element) {
                var cardbody = $(element).closest('.jsSearchableCardBody');
                var cardContainer = $(cardbody).closest('.jsSearchableCardContainer');
                var cardButton = $(cardContainer).find('>.jsSearchableCardHeader').find('.jsSearchableCollapser');
                if (!$(cardbody).hasClass('show')) {
                    $(cardButton).trigger('click');
                }
            });
            if (firstSearchRes != undefined) {
                firstSearchRes.scrollIntoView();
            }

        });
    }    
    function resetSearch() {
        collapseAll();
        totalOccurrences = 0;
        removeMatch(currBlock);
    }
    $(SEARCH_BAR).on("keyup", function (e) {
        var keyCode = e.keyCode || e.which;
        setBlocksFromTarget(e.target);
        if (searchText == "") {
            resetSearch();
            return;
        }
        if (keyCode == 13) {
            resetSearch();
            setTimeout(function () {
                highlightSearchText();
                postSearch()
            }, 400);
        }
    });


    $(COLLAPSE_BTN, accordions).click(function () {
        const accordion = $(this).closest('.jsSearchableExpandingContent');
        const isCollapseOthers = accordion.data('collapse-others') === 'True';
        if (isCollapseOthers) {
            accordion.find(CARD_BODY).collapse('hide');
        }
        $(this).closest(CARD_HEADER).next(CARD_BODY).collapse('toggle');
    });
    if (_hash.length) {
        if (document.getElementById(_hash.substring(1)).classList.contains('jsSearchableCollapser')) {
            $(_hash).trigger("click");
        }
    }
});

function regReplace(oldHtml, term) {
    let matches = oldHtml.match(new RegExp('(<[a-z1-9]+>.*?)(' + term + ')(.*?<\/[a-z1-9]+>)', 'ig'));
    if (matches != null && matches.length > 0) {
        //looks for the search term, with spaces or punctuation.
        var pattern = new RegExp("([ ]"+term+"[ .! ?\\-])", "gi");
        oldHtml = oldHtml.replace(pattern, "<mark>$1</mark>");
        oldHtml = oldHtml.replace(/(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/, "$1</mark>$2<mark>$4");
    }
    return oldHtml;
}

function removeMatch(currBlockElement) {
    $(currBlockElement).find('.searchMatchResult')
        .replaceWith((i, txt) => txt)
        .end()
        .html();
    $('mark').contents().unwrap();
}
String.prototype.replaceAt = function (index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}
function countOccurances(str, searchText) {
    let r = str.split(searchText).length - 1;
    return r;
} 
